
















































































































import Vue from 'vue';
import { VForm } from '../../plugins/vuetifyTypes';
import { AuthService } from '../../services/auth/AuthService';
import { SnackbarType } from '../../services/snackbar/models/SnackbarType';
import { SnackbarService } from '../../services/snackbar/SnackbarService';
import { formValidator } from '../../utils/FormValidator';
import { isString } from '../../utils/Utils';

export default Vue.extend({
  name: 'SetPasswordForm',

  data() {
    return {
      confirmPasswordValidator: formValidator.confirmPassword,
      isPasswordTooltipOpen: false,
      isLoading: false,

      token: '',

      formData: {
        password: '',
        confirmPassword: '',
      },
    };
  },

  methods: {
    onClickToggleIsPasswordTooltipOpen(): void {
      this.isPasswordTooltipOpen = !this.isPasswordTooltipOpen;
    },
    async onSubmit(): Promise<void> {
      this.isLoading = true;
      try {
        const validation = (this.$refs.form as VForm).validate();
        const token = this.$route.query?.token;
        if (validation && token && isString(token)) {
          await AuthService.setPassword(token, this.formData.password);
          await this.$router.push({ name: 'Login' });
        } else {
          this.isLoading = false;
        }
      } catch (error) {
        SnackbarService.open(
          this.$t('snackbar.somethingWentWrong'),
          SnackbarType.Error
        );
        this.isLoading = false;
      }
    },
  },
});
