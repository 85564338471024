






import SetPasswordForm from '../../components/auth/SetPasswordForm.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'SetPasswordPage',

  components: {
    SetPasswordForm,
  },
});
